import React, { useEffect, useState } from "react";

export default function Step1({ form, setForm }) {
  const [render, setRender] = useState(false);
  const dataOptions = [
    { label: "e.g.", value: "e.g." },
    { label: "weekly", value: "weekly" },
    { label: "bi-weekly", value: "bi-weekly" },
    { label: "monthly", value: "monthly" },
  ];

  useEffect(() => {
    setForm({
      start_date: "",
      end_date: "",
    });
    setRender(true);
  }, []);

  return (
    render && (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          marginRight: "10%",
          marginLeft: "10%",
        }}
      >
        <div
          style={{ marginBottom: "10px" }}
          className="d-flex align-items-center justify-content-between"
        >
          <label>Start date:</label>
          <div className="col-8">
            <input
              className="form-control"
              onChange={(e) => {
                setForm({ ...form, start_date: e.target.value });
              }}
              value={form.start_date}
              type="date"
            ></input>
          </div>
        </div>
        <div
          style={{ marginBottom: "10px" }}
          className="d-flex align-items-center justify-content-between"
        >
          <label>End date:</label>
          <div className="col-8">
            <input
              className="form-control"
              onChange={(e) => {
                setForm({ ...form, end_date: e.target.value });
              }}
              value={form.end_date}
              type="date"
            ></input>
          </div>
        </div>
      </div>
    )
  );
}
