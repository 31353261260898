import ButtonStyled from "../../../jsx/utils/ButtonStyled";

export default function Step4({calculatePayouts}) { 
  return (
    <div style={{ display: "flex", flexDirection: "column",alignItems:"center" }}>
      <ButtonStyled
        text="Calculate Payouts"
        onClickFunction={() => {
          calculatePayouts()
        }}
      />
    </div>
  );
}
